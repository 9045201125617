import React, { useEffect, useRef, useState } from "react";
import "./dataCounters.scss";
import LeftArc from "assets/images/dataCounter/LeftArc.png";
import RightArc from "assets/images/dataCounter/RightArc.png";
import Divider from "assets/images/dataCounter/Divider.png";
import Star from "assets/images/dataCounter/Star.svg";

function DataCounters() {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let interval;
    const handleScroll = () => {
      if (ref.current.getBoundingClientRect()?.top < 350) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section ref={ref} className="container data-counters-container">
      <div className="data-container">
        <span className="plusIcon">+</span>
        <div className="scrolling-text-container">
          <div
            className={
              isVisible ? "content-list scroll-effect-1" : "content-list"
            }
          >
            <div className="content-item">
              <h3>0</h3>
            </div>
            <div className="content-item">
              <h3>
                1k <span></span>
              </h3>
            </div>
          </div>
        </div>

        <p>
          Professionals <br /> trained
        </p>
      </div>
      <img className="divider-icon" src={Divider} alt="" />
      <div className="data-container">
        <span className="plusIcon">+</span>
        <div className="scrolling-text-container">
          <div
            className={
              isVisible ? "content-list scroll-effect-2" : "content-list "
            }
          >
            <div className="content-item">
              <h3>0</h3>
            </div>
            <div className="content-item">
              <h3>
                03 <span></span>
              </h3>
            </div>
            <div className="content-item">
              <h3>
                05<span></span>
              </h3>
            </div>
          </div>
        </div>
        <p>Years experience in Ab Initio training</p>
      </div>
      <img className="divider-icon" src={Divider} alt="" />
      <div className="data-container">
        <span className="plusIcon">+</span>
        <div className="scrolling-text-container">
          <div
            className={
              isVisible ? "content-list scroll-effect-2" : "content-list "
            }
          >
            <div className="content-item">
              <h3>0</h3>
            </div>
            <div className="content-item">
              <h3>
                2k <span></span>
              </h3>
            </div>
            <div className="content-item">
              <h3>
                3k <span></span>
              </h3>
            </div>
          </div>
        </div>
        <p>Hours of training & consulting</p>
      </div>
      <img className="divider-icon" src={Divider} alt="" />
      <div className="data-container">
        <span className="plusIcon">+</span>
        <div className="scrolling-text-container">
          <div
            className={
              isVisible
                ? "content-list scroll-effect-3"
                : "content-list content-list-clients"
            }
          >
            <div className="content-item">
              <h3>0</h3>
            </div>
            <div className="content-item">
              <h3>
                2 <span></span>
              </h3>
            </div>
            <div className="content-item">
              <h3>
                5 <span></span>
              </h3>
            </div>
            <div className="content-item">
              <h3>
                8 <span></span>
              </h3>
            </div>
            <div className="content-item">
              <h3>
                10 <span></span>
              </h3>
            </div>
            <div className="content-item">
              <h3>
                13 <span></span>
              </h3>
            </div>
            <div className="content-item">
              <h3>
                15 <span></span>
              </h3>
            </div>
            <div className="content-item">
              <h3>
                20 <span></span>
              </h3>
            </div>
          </div>
        </div>
        <p>
          Global <br />
          clients
        </p>
      </div>
      <img className="left-arc" src={LeftArc} alt="" />
      <img className="right-arc" src={RightArc} alt="" />
      <img className="right-star" src={Star} alt="" />
      <img className="left-star" src={Star} alt="" />
    </section>
  );
}

export default DataCounters;
