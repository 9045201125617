import React, { useEffect, useRef } from "react";
import "./dataJourney.scss";
// import { useInView } from "framer-motion";
import colorSwatch from "assets/images/dataJourney/color-swatch.svg";
import cardImg1 from "assets/images/dataJourney/card-1.png";
import cardImg2 from "assets/images/dataJourney/card-2.png";
import cardImg3 from "assets/images/dataJourney/card-3.png";
import cardImg4 from "assets/images/dataJourney/card-4.png";
import cardImg5 from "assets/images/dataJourney/card-5.png";
import cardImg6 from "assets/images/dataJourney/card-6.png";
import cardImg7 from "assets/images/dataJourney/card-7.png";
import dataCataloging from "assets/images/dataJourney/data-cataloging.svg";
import calendar from "assets/images/dataJourney/calendar.svg";
import testing from "assets/images/dataJourney/testing.svg";
import Productive from "assets/images/dataJourney/Productive.svg";
import Consulting from "assets/images/dataJourney/Consulting.svg";
import Talent from "assets/images/dataJourney/Talent.svg";

function DataJourney() {
  const ref = useRef();

  useEffect(() => {
    let yOffset = -39;

    if (window.innerWidth < 768) {
      yOffset = 270;
    } else {
      yOffset = -39;
    }

    if (location.hash.includes("capability")) {
      const element = ref.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const targetY = rect.top + scrollTop + yOffset;
        window.scrollTo({ top: targetY, behavior: "smooth" });
      }
    }
  }, [location, ref]);

  return (
    <section
      ref={ref}
      id="capabilities"
      className="modules container data-journey-container "
    >
      <div className="title-container" id="capability">
        <h2>Empower Your</h2>
        <h3>Data Journey</h3>
      </div>

      <div className="data-module-cards">
        <div className="card graphical-development">
          <div className="icon-container">
            <img src={colorSwatch} alt="" />
          </div>
          <div className="card-title-section">
            <h5>Graphical Development Training</h5>
            <p>
              Learn the basics of developing complex data processing
              applications through graphical development. Understand the
              components and functionality used for building data flows to solve
              business problems through good programming standards and
              guidelines
            </p>
          </div>
          <div className="image-container">
            <img src={cardImg1} alt="" />
          </div>
        </div>
        <div className="card data-cataloging">
          <div className="icon-container">
            <img src={dataCataloging} alt="" />
          </div>
          <div className="card-title-section">
            <h5>Data Cataloging & Governance</h5>
            <p>
              Learn how to discover, catalog, enrich, curate and access
              enterprise data. Understand and build an extensible and powerful
              metadata system for governance and data management applications
              that can enable enterprise
            </p>
          </div>
          <div className="image-container">
            <img src={cardImg2} alt="" />
          </div>
        </div>
        <div className="card administration">
          <div className="icon-container">
            <img src={calendar} alt="" />
          </div>
          <div className="card-title-section">
            <h5>Observability, Administration and Configuration</h5>
            <p>
              Learn the concepts of monitoring and scheduling jobs and understand
              how Ab Initio can help forward observability data in open source formats. 
              Learn how to install, configure and maintain an Ab Initio environment and
              understand the significance of parameters and how to control their
              behaviour
            </p>
          </div>
          <div className="image-container">
            <img src={cardImg3} alt="" />
          </div>
        </div>
        <div className="card devops">
          <div className="icon-container">
            <img src={testing} alt="" />
          </div>
          <div className="card-title-section">
            <h5>Cloud Deployments, Testing and DevOps</h5>
            <p>
              Learn how to Build, Test and Deploy Ab Initio applications on cloud platforms 
              or hybrid environments (both on-premise and in the cloud). Learn how to build
              meaningful test data for Unit, Integration and
              Regression Testing and integrate test automation capabilities and support for CI/CD
            </p>
          </div>
          <div className="image-container">
            <img src={cardImg4} alt="" />
          </div>
        </div>
        <div className="card hackathon">
          <div className="icon-container">
            <img src={Productive} alt="" />
          </div>
          <div className="card-title-section">
            <h5>Productive Hackathons</h5>
            <p>
              Keep your team involved by organizing focused Hackathons. Every
              team member gets to work as a developer, tester an administrator
              and get an experience of all aspects of an Ab Initio project life
              cycle
            </p>
          </div>
          <div className="image-container">
            <img src={cardImg5} alt="" />
          </div>
        </div>
        <div className="card consulting">
          <div className="icon-container">
            <img src={Consulting} alt="" />
          </div>
          <div className="card-title-section">
            <h5>Consulting Service</h5>
            <p>
              We engage with your technical architects to deliver workshops
              on customer platforms. We consult, mentor and hand
              hold your project teams to elevate their skills and help them
              deliver high quality projects
            </p>
          </div>
          <div className="image-container">
            <img src={cardImg6} alt="" />
          </div>
        </div>
        <div className="card talent-exchange">
          <div className="icon-container">
            <img src={Talent} alt="" />
          </div>
          <div className="card-title-section">
            <h5>Talent Exchange</h5>
            <p>
              Looking for the right job profile OR looking for the right talent
              to scale up your team? If you are an Ab Initio developer, tester
              or architect looking for a new challenge we will help you find
              your dream career. If you are an Organization looking to expand
              your Ab Initio team, we will help you find your dream team
            </p>
          </div>
          <div className="image-container">
            <img src={cardImg7} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DataJourney;
