import React, { useState } from "react";
import outArrow from "assets/images/footer/arrow_outward.svg";

function ContactUsCard({ data }) {
  const [isMouseEntered, setIsMouseEntered] = useState(false);
  const handleMouseInEvents = () => {
    setIsMouseEntered(true);
  };
  const handleMouseOutEvents = () => {
    setIsMouseEntered(false);
  };
  return (
    <div className="card-wrapper">
      <a
        href={data?.href ?? ""}
        target="_blank"
        rel="noreferrer"
        className="card"
        onMouseEnter={() => handleMouseInEvents()}
        onMouseLeave={() => handleMouseOutEvents()}
      >
        <div className="tiles-wrapper">
          <div className="tiles">
            <img
              src={!isMouseEntered ? data?.icon : data?.animatedIcon}
              alt=""
            />
          </div>
          <div className={isMouseEntered ? "arrow-out" : "arrow-none"}>
            {/* <a href={data?.href ?? ""} target="_blank" rel="noreferrer">
              <img src={outArrow} alt="" />
            </a> */}
            <img src={outArrow} alt="" />
          </div>
        </div>

        <div className="contact-details">
          <h4>{data?.cardTitle}</h4>
          <span>{data?.contactDetails}</span>
          <span>{data?.contactDetails1}</span>
        </div>
      </a>
    </div>
  );
}

export default ContactUsCard;
