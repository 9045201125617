import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./styles/global.scss";
import Home from "containers/Home/index";
import TermsAndConditions from "containers/TermsAndConditions/index";
import PrivacyPolicy from "containers/PrivacyPolicy/index";
import ScrollToTop from "components/ScrollToTop/index";
import InitialLoader from "components/LoaderPage/InitialLoader";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <InitialLoader location={location} />
      <Routes>
        <Route exact path={"/"} element={<Home />} />
        {/* <Route exact path={"/home"} element={<Home />} /> */}
        <Route
          exact
          path={"/terms-and-conditions"}
          element={<TermsAndConditions />}
        />
        <Route exact path={"/privacy-policy"} element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
