import { useEffect, useRef, useState } from "react";
import "./helloFromUs.scss";
import liftArrow from "../../assets/images/HomeBanner/lift arrow.gif";
import tower from "assets/images/helloFromUs/towers-before.png";

function HelloFromUs() {
  const ref = useRef(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let interval;
    const handleScroll = () => {
      const scrollPosition = window.scrollY || window.pageYOffset;
      const scrollThreshold = 450;
      if (ref.current?.getBoundingClientRect().top < scrollThreshold) {
        setIsPageLoading(false);

        // after setIsPageLoading(false) has been called
        if (scrollPosition > scrollThreshold + 250) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let yOffset = 50;

    if (window.innerWidth < 768) {
      yOffset = 50;
    } else {
      yOffset = 50;
    }

    if (location.hash.includes("aboutUs")) {
      const element = ref.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const scrollTop =
          window.pageYOffset || document.documentElement.scrollTop;
        const targetY = rect.top + scrollTop + yOffset;
        window.scrollTo({ top: targetY, behavior: "smooth" });
      }
    }
  }, [location, ref]);

  return (
    <section
      ref={ref}
      id="about"
      className="container hello-from-us-main-container"
    >
      <div className="next-page-indicator" id="aboutUs">
        <img src={liftArrow} alt="" />
      </div>
      <div ref={ref} className="main-container">
        <div className="title-container">
          <h2>Hello From</h2>
          <h3>Eleventh Floor</h3>
        </div>
      </div>
      <div className="empty-towers">
        <img
          className={isVisible ? "zoomed-tower" : "full-tower"}
          src={tower}
          style={{ opacity: !isPageLoading ? "1" : "0" }}
          alt=""
        />
        <div className="welcome-text-container">
          {isVisible && (
            <p className="about-us">
              Since 2018, we have built a team of certified Ab Initio
              professionals with deep expertise in BI technologies,
              methodologies, best practices and extensive experience in
              delivering projects for enterprise organizations across various
              domains
            </p>
          )}

          {isVisible && (
            <p className="our-passion">
              Our passion for Ab Initio Software Training evolved into our
              vision to help organizations accelerate their data processing
              journey. We are on a mission to help organizations build the right
              solutions that deliver long term strategic benefits
            </p>
          )}
        </div>
      </div>
    </section>
  );
}

export default HelloFromUs;
