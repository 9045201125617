import Header from "components/Header/Header";
import Banner from "components/HeroBanner/Banner";
import HelloFromUs from "components/HelloFromUs/HelloFromUs";
import DataJourney from "components/DataJourney/DataJourney";
import DataCounters from "components/DataCounter/DataCounters";
import PeopleSays from "components/PeopleSays/PeopleSays";
import Footer from "components/Footer/Footer";
// import InitialLoader from "components/LoaderPage/InitialLoader";
// import { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";

const Home = () => {
  // const [logoClicked, setLogoClicked] = useState(false);
  // const location = useLocation();
  // useEffect(() => {}, []);
  // Check if the user is in "/home"
  // const navigate = useNavigate(); // Use `useNavigate` from 'react-router-dom'

  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     setTimeout(() => {
  //       navigate("/");
  //     }, 50);
  //   }

  // const handleReload = () => {
  //   if (location.pathname === "/home") {
  //     setTimeout(() => {
  //       navigate("/");
  //     }, 50);
  //   }
  // };

  // window.addEventListener("beforeunload", handleReload);

  // return () => {
  //   window.removeEventListener("beforeunload", handleReload);
  // };
  // }, []);

  return (
    <div id="desktop-container">
      {/* <InitialLoader
        setLogoClicked={setLogoClicked}
        logoClicked={logoClicked}
        location={location}
      /> */}
      <Header />
      <Banner />
      <HelloFromUs />
      <DataJourney />
      <DataCounters />
      <PeopleSays />
      <Footer />
    </div>
  );
};

export default Home;
