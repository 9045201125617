import React, { useEffect, useState } from "react";
import "./homeBanner.scss";
import award from "../../assets/images/HomeBanner/award.svg";
import book from "../../assets/images/HomeBanner/book.svg";
import headphone from "../../assets/images/HomeBanner/headphone.svg";
import profile from "../../assets/images/HomeBanner/profile.svg";
import users from "../../assets/images/HomeBanner/users.svg";
import background from "../../assets/images/HomeBanner/background.mp4";
import backgroundSafari from "../../assets/images/HomeBanner/videoExport.png";
import backgroundMobile from "../../assets/images/HomeBanner/backgroundMobile.png";

function Banner() {
  const [textTranslate, setTextTranslate] = useState(0);
  const [textTranslate1, setTextTranslate1] = useState(0);
  const [textTranslate2, setTextTranslate2] = useState(0);
  const [textTranslate3, setTextTranslate3] = useState(0);
  const [textTranslate4, setTextTranslate4] = useState(0);
  const [textOpacity1, setTextOpacity1] = useState(1);
  const [textOpacity2, setTextOpacity2] = useState(0);
  const [textOpacity3, setTextOpacity3] = useState(0);
  const [textOpacity4, setTextOpacity4] = useState(0);

  const [isSafari, setIsSafari] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if the user agent indicates Safari
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (
      userAgent.indexOf("safari") !== -1 &&
      userAgent.indexOf("chrome") === -1
    ) {
      setIsSafari(true);
    }
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setIsMobile(true);
    }
  }, []);

  const features = [
    { icon: book, details: "Comprehensive Training Courses" },
    { icon: award, details: "Customised Workshops" },
    { icon: headphone, details: "Expert Consulting Services" },
    { icon: users, details: "Mentoring and Talent Exchange" },
    { icon: profile, details: "Productive Hackathons" }
  ];

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setTextTranslate(textTranslate + 100);
    }, 2500);

    if (textTranslate > 300) {
      setTextTranslate(0);
    }

    if (textTranslate === 0) {
      setTextTranslate1(0);
      setTextTranslate2(0);
      setTextTranslate3(0);
      setTextTranslate4(-400);
      setTextOpacity1(1);
      setTextOpacity2(0);
      setTextOpacity3(0);
      setTextOpacity4(0);
    } else if (textTranslate === 100) {
      setTextTranslate1(-100);
      setTextTranslate2(-100);
      setTextTranslate3(-100);
      setTextTranslate4(-100);
      setTextOpacity1(0);
      setTextOpacity2(1);
      setTextOpacity3(0);
      setTextOpacity4(0);
    } else if (textTranslate === 200) {
      setTextTranslate1(0);
      setTextTranslate2(-200);
      setTextTranslate3(-200);
      setTextTranslate4(-200);
      setTextOpacity1(0);
      setTextOpacity2(0);
      setTextOpacity3(1);
      setTextOpacity4(0);
    } else if (textTranslate === 300) {
      setTextTranslate1(100);
      setTextTranslate2(-300);
      setTextTranslate3(-300);
      setTextTranslate4(-300);
      setTextOpacity1(0);
      setTextOpacity2(0);
      setTextOpacity3(0);
      setTextOpacity4(1);
    }

    return () => clearTimeout(animationTimeout);
  }, [textTranslate]);

  return (
    <section className="bannerParent" id="hero-banner">
      <div id="home-section" className="container banner-main-container">
        <div className="title-section">
          <div className="title-swiper-container">
            <h2>Ab Initio </h2>
            <div className="scrolling-text-container">
              {/* content-list */}
              <div>
                {/* <div className="content-item">
                  <h3>Training</h3>
                </div>
                <div className="content-item">
                  <h3>Consulting</h3>
                </div>
                <div className="content-item">
                  <h3>Mentoring</h3>
                </div>
                <div className="content-item">
                  <h3>Talent Exchange</h3>
                </div> */}
                <p>
                  <h3
                    style={{
                      transform: `translateY(${textTranslate1}%)`,
                      opacity: `${textOpacity1}`
                    }}
                  >
                    Training
                  </h3>
                  <h3
                    style={{
                      transform: `translateY(${textTranslate2}%)`,
                      opacity: `${textOpacity2}`
                    }}
                  >
                    Consulting
                  </h3>
                  <h3
                    style={{
                      transform: `translateY(${textTranslate3}%)`,
                      opacity: `${textOpacity3}`
                    }}
                  >
                    Mentoring
                  </h3>
                  <h3
                    style={{
                      transform: `translateY(${textTranslate4}%)`,
                      opacity: `${textOpacity4}`
                    }}
                  >
                    Talent Exchange
                  </h3>
                </p>
              </div>
            </div>
          </div>
          <div className="welcome-text-container">
            
            <p>
              <span>Expand your Horizon </span> with Ab Initio software
              training, consulting, mentoring and recruitment services tailored
              to your needs by an authourized Ab Initio partner
              <br></br>
            </p>
            <p>
              This is <b>Eleventh Floor</b>
            </p>
          </div>
        </div>
        <div className="features-container">
          {features.map((item, index) => (
            <div key={index} className="icon-container">
              <div className="icon-containerLayer">
                <div className="icon">
                  <img src={item?.icon} alt="" />
                </div>
              </div>
              <div className="details-section">
                <p>{item?.details}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="space"></div>
        <div className="background-container">
          {/* <video autoPlay={true} muted loop playsInline>
            <source src={background} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video> */}

          {isSafari ? (
            <img
              src={isMobile ? backgroundMobile : backgroundSafari}
              alt="Background"
              className="safariBgImage"
              style={{ outline: "none" }}
            />
          ) : (
            <video autoPlay muted loop playsInline style={{ outline: "none" }}>
              <source src={background} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          )}
        </div>
      </div>
    </section>
  );
}

export default Banner;
