import React from "react";
import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import PrivacyData from "components/PrivacyData/index";

const PrivacyPolicy = () => {
  return (
    <div id="desktop-container">
      <Header />
      <PrivacyData />
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
