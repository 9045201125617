import React from "react";
import "./footer.scss";
import Arrow from "assets/images/footer/arrowTop.svg";
import ArrowGradiant from "assets/images/footer/arrow-up-gradiant.svg";
import ContactUsCard from "./ContactUsCard";
import mail from "assets/images/footer/_mail.svg";
import location from "assets/images/footer/_location.svg";
import mobile from "assets/images/footer/_mobile.svg";
import linkedin from "assets/images/footer/_linkdIn.svg";
import AnimatedMail from "assets/images/footer/AnimatedMail.gif";
import AnimatedPhone from "assets/images/footer/AnimatedPhone.gif";
import AnimatedLinkdIN from "assets/images/footer/AnimatedLinkdIN.gif";
import AnimatedLocation from "assets/images/footer/AnimatedLocation.gif";
import { useState } from "react";
import { Link } from "react-router-dom";

function Footer() {
  const [isMouseEntered, setIsMouseEntered] = useState(false);

  const handleMouseInEvents = () => {
    setIsMouseEntered(true);
  };
  const handleMouseOutEvents = () => {
    setIsMouseEntered(false);
  };

  const handleBacktoTop = () => {
    const homeSection = document.getElementById("home-section");
    if (homeSection) {
      homeSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const data = [
    {
      icon: mail,
      cardTitle: "Mail us",
      contactDetails: "info@eleventhfloorcompany.com",
      contactDetails1: "",
      animatedIcon: AnimatedMail,
      href: "mailto:info@eleventhfloorcompany.com"
    },
    {
      icon: mobile,
      cardTitle: "Call us",
      contactDetails: "+91 9076-353836",
      contactDetails1: "",
      animatedIcon: AnimatedPhone,
      href: "tel:+919076353836"
    },
    {
      icon: location,
      cardTitle: "Find us",
      contactDetails: "Eleventh Floor Solutions Pvt. Ltd.,",
      contactDetails1: "Baner, Pune, 411021",
      animatedIcon: AnimatedLocation,
      href: "https://maps.app.goo.gl/WCbgmn959xwuUFMJ9"
    },
    {
      icon: linkedin,
      cardTitle: "Follow us",
      contactDetails: "Eleventh Floor Solutions Pvt. Ltd. on LinkedIn",
      contactDetails1: "      ",
      animatedIcon: AnimatedLinkdIN,
      href: "https://www.linkedin.com/company/eleventhfloorcompany/about/"
    }
  ];
  return (
    <div className="footer-wrapper">
      <section id="footer" className="container footer-main-container">
        <div className="title-container">
          <h2>Get In</h2>
          <div className="b-to-top-container">
            <h3>Touch With Us</h3>
            <p
              onMouseEnter={() => handleMouseInEvents()}
              onMouseLeave={() => handleMouseOutEvents()}
              onClick={() => handleBacktoTop()}
            >
              Back to Top
              <span>
                <img
                  className={isMouseEntered ? "upArrow-fade-out " : "upArrow"}
                  src={Arrow}
                  alt=""
                />
                <img
                  className={
                    isMouseEntered ? "upArrow-fade-in " : "upArrow-gradiant"
                  }
                  src={ArrowGradiant}
                  alt=""
                />
              </span>
            </p>
          </div>
        </div>
        <div>
          <div className="footer-card-container">
            {data?.map((item, index) => (
              <ContactUsCard key={index} data={item} />
            ))}
          </div>
          <div className="termsPrivacy">
            <span>
              <Link to={"/terms-and-conditions"}>Terms of Use</Link>
            </span>
            <span>|</span>
            <span>
              <Link to={"/privacy-policy"}>Privacy & Cookies</Link>
            </span>
          </div>
        </div>
        <div className="mobile-b-top">
          <p>Back to Top</p>
          <span onClick={() => handleBacktoTop()}>
            <img src={Arrow} alt="" />
          </span>
        </div>
      </section>
      <div className="copy-rights-container">
        <p>© eleventh floor</p>
        <div className="end-line"></div>
      </div>
    </div>
  );
}

export default Footer;
