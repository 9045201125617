import { useState } from "react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./header.scss";

import logo from "assets/images/eleventhFloorLogo.svg";
import arrowRight from "assets/images/headers/arrow_right.svg";
import mobileMenu from "assets/images/headers/mobile-menu.svg";
import menuClose from "assets/images/headers/closeMenu.svg";
import { Link } from "../../../node_modules/react-router-dom/dist/index";

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  function handleNavigation(sectionId, customOffsets = {}) {
    const defaultOffset = -50;
    const headerOffset = customOffsets[sectionId] || defaultOffset;
    const element = document.getElementById(sectionId);
    if (element) {
      const elementPosition = element?.getBoundingClientRect().top;
      const offsetPosition =
        elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }

  const onClickMobile = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.querySelector("body").style.overflow = "hidden";
      document.getElementById("headerSubContainer").style.backdropFilter =
        "none";
    } else {
      document.querySelector("body").style.overflow = "auto";
      document.getElementById("headerSubContainer").style.backdropFilter =
        "blur(12.5px)";
    }
  }, [isMenuOpen]);

  const handleBacktoTop = () => {
    const headSection = document.getElementById("hero-banner");

    if (window.location.pathname === "/") {
      // If on the "/" page, scroll to the top
      if (headSection) {
        headSection.scrollIntoView({ behavior: "smooth" });
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } else {
      // If on any other page, navigate to the "/" page
      navigate("/");
    }
  };

  // const handleBacktoTop = () => {
  //   const headSection = document.getElementById("hero-banner");
  //   if (
  //     window.location.pathname === "/" ||
  //     window.location.pathname === "/home"
  //   ) {
  //     if (headSection) {
  //       headSection.scrollIntoView({ behavior: "smooth" });
  //     } else {
  //       navigate("/");
  //     }
  //   } else if (
  //     window.location.pathname === "/privacy-policy" ||
  //     window.location.pathname === "/terms-and-conditions"
  //   ) {
  //     navigate("/");
  //   }
  // };

  const renderLink = () => {
    if (
      location.pathname === "/privacy-policy" ||
      location.pathname === "/terms-and-conditions"
    ) {
      return (
        <>
          <p className="header-links">
            <Link to={"/#aboutUs"}>About</Link>
          </p>
          <p className="header-links">
            <Link to={"/#capability"}>Capabilities</Link>
          </p>
          <p className="header-links">
            <Link to={"/#testimonial"}> Testimonials</Link>
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className="header-links" onClick={() => handleNavigation("about")}>
            About
          </p>
          <p
            className="header-links"
            onClick={() =>
              handleNavigation("capabilities", { capabilities: 45 })
            }
          >
            Capabilities
          </p>
          <p
            className="header-links"
            onClick={() =>
              handleNavigation("testimonials", { testimonials: 117 })
            }
          >
            Testimonials
          </p>
        </>
      );
    }
  };

  const renderLinkResponsive = () => {
    if (
      location.pathname === "/privacy-policy" ||
      location.pathname === "/terms-and-conditions"
    ) {
      return (
        <>
          <p className="header-links">
            <Link to={"/#aboutUs"}>About</Link>
          </p>
          <p className="header-links">
            <Link to={"/#capability"}>Capabilities</Link>
          </p>
          <p className="header-links">
            <Link to={"/#testimonials"}> Testimonials</Link>
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className="header-links" onClick={() => handleNavigation("about")}>
            About
          </p>
          <p
            className="header-links"
            onClick={() =>
              handleNavigation("capabilities", { capabilities: 15 })
            }
          >
            Capabilities
          </p>
          <p
            className="header-links"
            onClick={() =>
              handleNavigation("testimonials", { testimonials: 54 })
            }
          >
            Testimonials
          </p>
        </>
      );
    }
  };

  return (
    <div className="header-main-container">
      <div
        className="header-sub-container container"
        id="headerSubContainer"
        style={{
          "-webkit-backdrop-filter": "blur(12.5px)"
        }}
      >
        <div className="logo-container">
          <img src={logo} alt="" onClick={() => handleBacktoTop()} />
        </div>
        <div className="link-container">
          {renderLink()}
          <button
            onClick={() => handleNavigation("footer", { footer: 65 })}
            className="header-link-button"
          >
            Get in Touch
            <img src={arrowRight} alt="" />
          </button>
        </div>
        <div onClick={onClickMobile} className="mobile-menu">
          <img src={isMenuOpen ? menuClose : mobileMenu} alt="" />
          {isMenuOpen && (
            <div className="mobile-menuParent">
              <div className="menu-popup">
                <div className="mobile-links">{renderLinkResponsive()}</div>
                <div>
                  <button
                    onClick={() => handleNavigation("footer", { footer: 65 })}
                    className="header-link-button"
                  >
                    Get in Touch
                    <img src={arrowRight} alt="" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Header;
