import React from "react";

const PrivacyData = () => {
  return (
    <section className="termsData" id="home-section">
      <div className="termsDataContainer container">
        <div className="termsDataTitle">
          <h2>Privacy & Cookies</h2>
        </div>
        <div className="termsDataPoints">
          <div className="dataPoints">
            <h3>1. Information We Collect</h3>
            <div>
              <p>
                a. Personal Information: Eleventh Floor may collect personal
                information, such as names, email addresses, and contact
                details, when voluntarily provided by users during the
                registration or contact process.
              </p>
              <p>
                b. Usage Information: We may collect non-personal information
                about users&apos; interactions with the Website, including IP
                addresses, browser types, and pages viewed, to improve our
                services.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>2. How We Use Your Information</h3>
            <div>
              <p>
                a. Eleventh Floor uses the collected information for the purpose
                of providing Ab Initio software training, mentoring, and
                consultation services.
              </p>
              <p>
                b. Personal information may be used to communicate with users,
                process payments, and customize the content and layout of the
                Website.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>3. Information Sharing</h3>
            <div>
              <p>
                a. Eleventh Floor does not sell, trade, or rent users&apos;
                personal information to third parties. We may share information
                with trusted partners who assist us in operating our Website,
                conducting our business, or servicing users, but such parties
                agree to keep this information confidential.
              </p>
              <p>
                b. We may also disclose personal information when required by
                law or to protect our rights and safety.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>4. Cookies and Tracking Technologies</h3>
            <div>
              <p>
                a. Eleventh Floor uses cookies and similar tracking technologies
                to enhance user experience and collect information about how the
                Website is used. Users can control cookie preferences within
                their browsers.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>5. Security</h3>
            <div>
              <p>
                a. Eleventh Floor employs reasonable security measures to
                protect users&apos; personal information from unauthorized
                access, disclosure, alteration, or destruction.
              </p>
              <p>
                b. Users agree not to engage in any conduct that may disrupt or
                interfere with the proper functioning of the Website or services
                provid
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>6. Third-Party Links</h3>
            <div>
              <p>
                a. The Website may contain links to third-party websites.
                Eleventh Floor is not responsible for the privacy practices or
                content of these external sites.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>7. Your Choices</h3>
            <div>
              <p>
                a. Users have the right to access, update, or delete their
                personal information. To exercise these rights, please contact
                Eleventh Floor.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>8. Changes to Privacy Policy</h3>
            <div>
              <p>
                a. Eleventh Floor reserves the right to update this Privacy
                Policy at any time. Users are encouraged to review the policy
                periodically for changes.
              </p>
            </div>
          </div>
          <h5 className="termsDataLastUpdate">
            <span>Last updated :</span> 29/04/2024
          </h5>
        </div>
      </div>
    </section>
  );
};

export default PrivacyData;
