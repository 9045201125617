import React from "react";
import "components/TermsData/termsData.scss";

const TermsData = () => {
  return (
    <section className="termsData" id="home-section">
      <div className="termsDataContainer container">
        <div className="termsDataTitle">
          <h2>Terms & Conditions</h2>
        </div>
        <div className="termsDataPoints">
          <div className="dataPoints">
            <h3>1. Acceptance of Terms</h3>
            <div>
              <p>
                By accessing and using Eleventh Floor website, you agree to
                comply with and be bound by these Terms and Conditions. If you
                do not agree to these terms, please refrain from using our
                services.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>2. Intellectual Property</h3>
            <div>
              <p>
                All content, materials, and resources provided on the Website,
                including but not limited to text, images, logos, and software,
                are the intellectual property of Eleventh Floor and are
                protected by applicable copyright and trademark laws.
                Unauthorized use of any materials may violate copyright and
                trademark laws.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>3. Services and Payments</h3>
            <div>
              <p>
                a. Eleventh Floor offers Ab Initio software training, mentoring,
                and consultation services. Details regarding the services
                offered, including pricing and payment terms, are as per contract.
              </p>
              <p>
                b. Payments for services must be made in accordance with the
                payment terms outlined in the contract. Failure to make timely
                payments may result in the suspension of services.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>4. Refund Policy</h3>
            <div>
              <p>
                a. Refunds are issued at the discretion of Eleventh Floor.
                Requests for refunds must be submitted in writing and will be
                considered based on the circumstances.
              </p>
              <p>
                b. Eleventh Floor reserves the right to modify or discontinue
                any service without notice at any time. Refunds may be provided
                for discontinued services at our sole discretion.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>5. User Conduct</h3>
            <div>
              <p>
                a. Users are responsible for maintaining the confidentiality of
                the information shared by Eleventh Floor. Any unauthorized use of
                information must be reported immediately to Eleventh Floor.
              </p>
              <p>
                b. Users agree not to engage in any conduct that may disrupt or
                interfere with the proper functioning of Eleventh Floor or services
                provided
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>6. Limitation of Liability</h3>
            <div>
              <p>
                Eleventh Floor shall not be liable for any direct, indirect,
                incidental, special, or consequential damages arising out of the
                use or inability to use our services.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>7. Governing Law</h3>
            <div>
              <p>
                These Terms and Conditions are governed by and construed in
                accordance with the laws of India. Any disputes arising from or
                in connection with these terms shall be subject to the exclusive
                jurisdiction of the courts in India.
              </p>
            </div>
          </div>
          <div className="dataPoints">
            <h3>8. Changes to Terms</h3>
            <div>
              <p>
                Eleventh Floor reserves the right to modify these Terms and
                Conditions at any time without prior notice. Users are
                encouraged to review these terms regularly for updates.
              </p>
            </div>
            <p>
              By using our services, you acknowledge that you have read,
              understood, and agree to be bound by these Terms and Conditions.
            </p>
          </div>
          <h5 className="termsDataLastUpdate">
            <span>Last updated :</span> 29/04/2024
          </h5>
        </div>
      </div>
    </section>
  );
};

export default TermsData;
