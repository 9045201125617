import Footer from "components/Footer/Footer";
import Header from "components/Header/Header";
import TermsData from "components/TermsData/index";
import React from "react";

const TermsAndConditions = () => {
  return (
    <div id="desktop-container">
      <Header />
      <TermsData />
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
