import React, { useEffect, useRef, useState } from "react";
import { setToSession } from "utils/index";
import "../../styles/global.scss";
import LoaderVideo from "assets/images/updateLoader.mp4";
// import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";

function InitialLoader() {
  // const location = useLocation();
  // const navigate = useNavigate();

  const loadRef = useRef(null);
  const [loadedOnce, setLoadedOnce] = useState(false);
  useEffect(() => {
    // if (!logoClicked) {

    // setTimeout(() => {
    //   navigate("/");
    // }, 5000);

    // if (location.pathname !== "/home") {
    console.log("clicked");
    setLoadedOnce(false);
    setTimeout(() => {
      if (loadRef.current) {
        loadRef.current.style.transform = "translateY(-150%)";
      }
    }, 5000);
    setTimeout(() => {
      if (loadRef.current) {
        loadRef.current.style.display = "translateY(-150%)";
      }
      setToSession("loadedOnce", true);
    }, 5000);
    // } else {
    //   setLoadedOnce(true);
    // }
  }, []);

  return (
    !loadedOnce && (
      <div
        ref={loadRef}
        className="container loader-container"
        style={{ outline: "none", border: "none" }}
      >
        <video
          autoPlay={true}
          muted
          // loop
          playsInline
          style={{ outline: "none", border: "none" }}
        >
          <source src={LoaderVideo} type="video/mp4" />
        </video>
      </div>
    )
  );
}

export default InitialLoader;
